import {useCookies} from 'vue3-cookies'
import type {Company} from '../services/CompanyService'
import {decodeBase64, encodeBase64} from './utils'

const COMPANY_PROPERTIES_KEY = 'companyProperties'

interface CompanyProperties {
  id: number
  name: string
  currency?: string
  timeZone?: string
}

class CompanyPropertiesCookie {
  get(): CompanyProperties | undefined {
    const cookies = useCookies().cookies
    const cookieString = cookies.get('companyProperties')
    return cookieString ? decodeBase64(cookieString) : undefined
  }

  isSet() {
    const cookies = useCookies().cookies
    return cookies.isKey('companyProperties')
  }

  remove() {
    const {cookies} = useCookies()
    cookies.remove(COMPANY_PROPERTIES_KEY)
  }

  set(company: Company) {
    const {cookies} = useCookies()
    const companyProperties: CompanyProperties = {
      id: company.id,
      name: company.name,
      currency: company.currency || undefined,
      timeZone: company.timeZone || undefined,
    }

    cookies.set(COMPANY_PROPERTIES_KEY, encodeBase64(companyProperties))
  }
}

export default new CompanyPropertiesCookie()
